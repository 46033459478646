import React, { FC } from 'react';
import { ICreditCards } from './CreditCards.def';
import CategoryNav from '../CategoryNav';
import FeaturedHub from '../FeaturedHub';
import GuideBlocks from '../GuideBlocks';
import HorizontalBrowser from '../HorizontalBrowser';
import PageHeader from '../PageHeader';
import SquareBrowser from '../SquareBrowser';
import { TopRelatedContent } from '../TopRelatedContent';

const CreditCards: FC<ICreditCards> = ({
  pageHeader,
  categoryNav,
  creditCardReviews,
  essentialReading,
  earnPoints,
  redeemPoints,
  latestCreditCardGuides,
  topRelatedContent,
}) => {
  return (
    <>
      <PageHeader {...pageHeader} />
      <CategoryNav {...categoryNav} />
      <HorizontalBrowser {...creditCardReviews} />
      <SquareBrowser {...essentialReading} />
      <FeaturedHub {...earnPoints} />
      <FeaturedHub {...redeemPoints} />
      <GuideBlocks {...latestCreditCardGuides} />
      {topRelatedContent?.title && <TopRelatedContent {...topRelatedContent} />}
    </>
  );
};

export default CreditCards;
