import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../../components/Seo';
import { getArticles, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import { CreditCards } from '../../components/CreditCards';
import { creditCardsRelatedContent } from '../../data/relatedContent';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface ICreditCardsPageProps {
  essentialReading: Queries.WpPostConnection;
  earnPoints: Queries.WpPostConnection;
  redeemPoints: Queries.WpPostConnection;
  latestCreditCardGuides: Queries.WpPostConnection;
}

const CreditCardsPage = ({ data, location }: PageProps<ICreditCardsPageProps>) => {
  const { earnPoints, redeemPoints, essentialReading, latestCreditCardGuides } = data;
  let latestCreditCardPosts: Queries.WpPost[] = [];
  let redeemPointsPosts: Queries.WpPost[] = [];
  let earnPointsPosts: Queries.WpPost[] = [];
  let essentialReadingPosts: Queries.WpPost[] = [];

  if (latestCreditCardGuides && Array.isArray(latestCreditCardGuides.nodes)) {
    latestCreditCardPosts = latestCreditCardGuides.nodes;
  }

  if (redeemPoints && Array.isArray(redeemPoints.nodes)) {
    redeemPointsPosts = redeemPoints.nodes;
  }

  if (earnPoints && Array.isArray(earnPoints.nodes)) {
    earnPointsPosts = earnPoints.nodes;
  }

  if (essentialReading && Array.isArray(essentialReading.nodes)) {
    essentialReadingPosts = essentialReading.nodes;
  }

  const latestCreditCardArticles = getArticles(latestCreditCardPosts);
  const redeemPointsArticles = getArticles(redeemPointsPosts).map((article) => ({
    ...article,
    moreLink: {
      text: 'Learn More',
      link: article.link,
    },
  }));
  const earnPointsArticles = getArticles(earnPointsPosts).map((article) => ({
    ...article,
    moreLink: {
      text: 'Learn More',
      link: article.link,
    },
  }));
  const essentialReadingArticles = getArticles(essentialReadingPosts).map((article) => ({
    ...article,
    category: {
      link: false,
      text: 'Credit Cards',
    },
    imageOverlay: true,
  }));
  // Card IDs to be confirmed
  const cardIds = [5, 2085, 205, 1983, 2991, 21336];
  const creditCards = cardIds.map((cardId) => ({ cardId }));
  const title = 'Credit Cards';

  return (
    <HTPageWrapper category="Hub Page, Credit Cards" title={title} location={location}>
      <CreditCards
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
          ],
        }}
        categoryNav={{
          variant: 'category',
          subtitle: 'Explore the best credit cards for all your travel needs',
          categories: [
            {
              link: '/credit-cards/business/best-business-credit-cards/',
              text: 'Business',
              class: 'cat-business',
            },
            {
              link: '/credit-cards/travel-rewards-credit-cards/',
              text: 'Travel',
              class: 'cat-travel',
            },
            { link: '/credit-cards/airline-credit-cards/', text: 'Airline', class: 'cat-airlines' },
            { link: '/credit-cards/hotel/', text: 'Hotel', class: 'cat-hotels' },
            {
              link: '/credit-cards/sign-up-bonuses/',
              text: 'Sign Up Bonuses',
              class: 'cat-bonuses',
            },
            {
              link: '/credit-cards/0-percent-interest/',
              text: 'APR Cards',
              class: 'cat-NoAnnualFee',
            },
            {
              link: '/credit-cards/cash-back/',
              text: 'Cash-Back',
              class: 'cat-CashBack',
            },
          ],
          allLink: '/credit-cards/best/',
          allText: 'All credit cards',
        }}
        creditCardReviews={{
          seeAllLink: {
            link: '/credit-cards/reviews/',
            text: 'See All Reviews',
          },
          cards: creditCards,
          title: 'Credit Card Reviews',
          subtitle:
            'Our experts do a thorough analysis of all of the most popular credit cards on the market',
        }}
        earnPoints={{
          title: 'Best Ways To Earn Points',
          subtitle:
            'Explore the different credit card points programs and learn how to maximize your credit card points.',
          variant: 'blue',
          articles: earnPointsArticles,
        }}
        essentialReading={{
          articles: essentialReadingArticles,
          title: 'Essential Reading',
          moreLink: {
            link: ' /credit-cards/guides/all/',
            text: 'See All',
          },
        }}
        redeemPoints={{
          title: 'Best Ways To Redeem Your Points',
          subtitle:
            'Learn all the different ways to use your credit card points, with detailed guides, tips and tricks from our experts.',
          variant: 'blue',
          articles: redeemPointsArticles,
        }}
        latestCreditCardGuides={{
          moreLink: {
            link: '/credit-cards/guides/all/',
            text: 'See More',
          },
          variant: 'columns',
          articles: latestCreditCardArticles,
          title: 'Latest Credit Card Guides',
        }}
        topRelatedContent={{
          title: 'Top Credit Card Content',
          categories: creditCardsRelatedContent,
        }}
      />
    </HTPageWrapper>
  );
};

export default CreditCardsPage;

export const query = graphql`
  query CreditCardsQuery {
    latestCreditCardGuides: allWpPost(
      sort: { date: DESC }
      limit: 6
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Credit Cards" } } } }
        template: { templateName: { ne: "Credit Card Review" } }
      }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        author {
          node {
            uri
            name
          }
        }
        title
        uri
        dateGmt
        excerpt
      }
    }
    redeemPoints: allWpPost(filter: { databaseId: { in: [459, 239170, 178072, 150197] } }) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    earnPoints: allWpPost(filter: { databaseId: { in: [255622, 380, 309359, 296092] } }) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    essentialReading: allWpPost(
      filter: { databaseId: { in: [333624, 328146, 322103, 322139, 269492, 261009] } }
    ) {
      nodes {
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            id
            parentId
          }
        }
        title
        uri
      }
    }
  }
`;

export const Head = ({ location, data }: PageProps<ICreditCardsPageProps>) => {
  const pageTitle = 'Browse Credit Card Reviews, News and Guides';
  const socialTitle = pageTitle;
  const ogDescription =
    'Our credit card experts provide everything you need to earn and spend like a pro, with our reviews, news and guides.';
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [{ name: 'Credit Cards', slug: 'credit-cards' }];
  const featuredPost = data?.essentialReading?.nodes[0];
  const featuredImageData = featuredPost ? getFeaturedImageDataFromWpPost(featuredPost) : null;
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      isArchivePage={true}
      archiveName={'Credit Cards'}
      uri={'/credit-cards/'}
    />
  );
};
