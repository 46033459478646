import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ITopRelatedContent } from './TopRelatedContent.def';

const TopRelatedContent: FC<ITopRelatedContent> = ({ title, categories }) => {
  return (
    <section className="top-related-content-wrapper">
      <div className="container">
        <p className="top-related-content-heading">{title}</p>
        <div className="top-related-content-categories">
          {categories.map(({ links, title, viewAllLink }) => {
            return (
              <div key={title} className="top-related-content-category">
                <p className="top-related-category-heading">{title}</p>
                <ul className="top-related-content-links">
                  {links.map(({ link, text }) => (
                    <li key={text}>{link && <Link to={link}>{text}</Link>}</li>
                  ))}
                </ul>
                {viewAllLink?.link && (
                  <Link to={viewAllLink.link} className="btn-standard btn-small">
                    {viewAllLink.text}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TopRelatedContent;
